<template>
  <div style="margin: -20px">
    <iframe
      id="frame"
      src="https://grafana.quchuxing.com/d/BizBiDui/shu-ju-dui-bi?orgId=1&kiosk=tv"
    ></iframe>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
iframe {
  width: 100%;
  height: 100vh;
  position: relative;
  border: 0px;
  margin: 0;
}
</style>
